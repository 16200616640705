import React from 'react';
import NavChart from './NavChart';
import { useSelector } from 'react-redux';

const Strategy = () => {
  //Helper variables

  const mFundTitle = 'Managed-futures performance';
  const iFundTitle = 'Icarus-trend performance';

  let liveMFundPerformance = useSelector((state) => state.mfundPerformanceData);
  let liveIFundPerformance = useSelector((state) => state.ifundPerformanceData);

  if (liveMFundPerformance.loading || liveIFundPerformance.loading) {
    return <p></p>;
  }

  return (
    <React.Fragment>
      <div className="h-12"></div>
      <p className="text-xl sm:text-3xl font-semibold ml-4 sm:ml-14 text-white">Our Fund Strategies</p>
      <div className="border-t-8 border-green-600 w-12 mt-5 ml-4 sm:ml-14"></div>
      <div className="sm:flex sm:flex row mt-6">
        <div className="ml-4 sm:ml-14 sm:w-5/12 mb-8">
          <p className="text-green-500 text-lg">Managed Futures Fund</p>
          <p className="text-white mt-2">
            A long-term strategy focused on periodically investing and divesting in the most trusted asset classes such
            as Bitcoin and Etherum.
          </p>
        </div>
        <div id="iFundStrategyText" className="ml-4 sm:ml-14 sm:w-5/12">
          <p className="text-green-500 text-lg">Icarus Trend Fund</p>
          <p className="text-white mt-2">
            Strategic selection of the market's top asset classes with automatic and frequent rebalance for optimal
            performance.
          </p>
        </div>
      </div>
      <div className="sm:flex sm:flex row sm:pb-14">
        <div className="pb-10 sm:pb-0 sm:ml-10 sm:w-5/12 mt-14 sm:mt-0">
          <NavChart fundPerformance={liveMFundPerformance} FundTitle={mFundTitle} />
        </div>
        <div id="iFundStrategy" className="pb-10 sm:pb-0 mt-6 sm:mt-0 sm:ml-10 sm:w-5/12">
          <NavChart fundPerformance={liveIFundPerformance} FundTitle={iFundTitle} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Strategy;
