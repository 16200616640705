import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import actions from '../actions';
import { useDispatch } from 'react-redux';

import Home from './Home';
import SignUp from './SignUp';
import Login from './Login';
import Account from './Account/Account';
import Admin from './Admin'
import NotFound from './NotFound';

const App = () => {
  //create dispatch function
  let dispatch = useDispatch();

  //run on first render
  useEffect(() => {
    dispatch(actions.fetchUser());
    dispatch(actions.fetchFundData());
    dispatch(actions.fetchMfundPerformanceData());
    dispatch(actions.fetchIfundPerformanceData());
    const interval = setInterval(() => {
      dispatch(actions.fetchFundData());
    }, 180000); // 1000 * 60 * 3
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
