export const FETCH_USER_REQUEST = 'fetch_user_request';
export const FETCH_USER_SUCCESS = 'fetch_user_success';
export const FETCH_USER_FAILURE = 'fetch_user_failure';

export const FETCH_FUND_DATA_REQUEST = 'fetch_fund_data';
export const FETCH_FUND_DATA_SUCCESS = 'fetch_fund_data_success';
export const FETCH_FUND_DATA_FAILURE = 'fetch_fund_data_failure';

export const FETCH_MFUND_PERFORMANCEDATA_REQUEST = 'fetch_Mfund_performanceData';
export const FETCH_MFUND_PERFORMANCEDATA_SUCCESS = 'fetch_Mfund_performanceData_success';
export const FETCH_MFUND_PERFORMANCEDATA_FAILURE = 'fetch_Mfund_performanceData_failure';

export const FETCH_IFUND_PERFORMANCEDATA_REQUEST = 'fetch_Ifund_performanceData';
export const FETCH_IFUND_PERFORMANCEDATA_SUCCESS = 'fetch_Ifund_performanceData_success';
export const FETCH_IFUND_PERFORMANCEDATA_FAILURE = 'fetch_Ifund_performanceData_failure';

export const FETCH_MFUND_MONTHLY_PNL_REQUEST = 'fetch_Mfund_monthlyPnL';
export const FETCH_MFUND_MONTHLY_PNL_SUCCESS = 'fetch_Mfund_monthlyPnL_success';
export const FETCH_MFUND_MONTHLY_PNL_FAILURE = 'fetch_Mfund_monthlyPnL_failure';

export const FETCH_IFUND_MONTHLY_PNL_REQUEST = 'fetch_Ifund_monthlyPnL';
export const FETCH_IFUND_MONTHLY_PNL_SUCCESS = 'fetch_Ifund_monthlyPnL_success';
export const FETCH_IFUND_MONTHLY_PNL_FAILURE = 'fetch_Ifund_monthlyPnL_failure';

export const FETCH_ADMIN_DATA_REQUEST = 'fetch_admin_data_request';
export const FETCH_ADMIN_DATA_SUCCESS = 'fetch_admin_data_success';
export const FETCH_ADMIN_DATA_FAILURE = 'fetch_admin_data_failure';
