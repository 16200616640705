import React from 'react';
import { useSelector } from 'react-redux';

const Ticker = ({ numberWithCommas }) => {
  let liveFData = useSelector((state) => state.liveFundData);

  if (liveFData.loading) {
    return <p></p>;
  }

  return (
    <div className="scroll pt-8">
      <div>
        <span className="ml-24 text-white">{liveFData.data[1].officialName}:</span>
        <span className="ml-3 text-white">Current NAV</span>
        <span className="text-green-500 ml-2">{liveFData.data[1].predicted_nav.toFixed(3)}</span>
        <span className="ml-3 text-white">Daily Performance </span>
        <span className={`${liveFData.data[1].dtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[1].dtd_performance_usd.toFixed(2))} (
          {(liveFData.data[1].dtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white">Monthly Performance </span>
        <span className={`${liveFData.data[1].mtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[1].mtd_performance_usd.toFixed(2))} (
          {(liveFData.data[1].mtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white ">Yearly Performance </span>
        <span className={`${liveFData.data[1].ytd_performance > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {(liveFData.data[1].ytd_performance * 100).toFixed(2)}%
        </span>
        <span className="text-white ml-24">{liveFData.data[0].officialName}:</span>
        <span className="ml-3 text-white">Current NAV</span>
        <span className="text-green-500 ml-2">{liveFData.data[0].predicted_nav.toFixed(3)}</span>
        <span className="ml-3 text-white">Daily Performance </span>
        <span className={`${liveFData.data[0].dtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[0].dtd_performance_usd.toFixed(2))} (
          {(liveFData.data[0].dtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white">Monthly Performance </span>
        <span className={`${liveFData.data[0].mtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[0].mtd_performance_usd.toFixed(2))} (
          {(liveFData.data[0].mtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white ">Yearly Performance </span>
        <span className={` mr-24 ${liveFData.data[0].ytd_performance > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {(liveFData.data[0].ytd_performance * 100).toFixed(2)}%
        </span>
      </div>
      <div>
        <span id="secondTickerBody" className="text-white">
          {liveFData.data[1].officialName}:
        </span>
        <span className="ml-3 text-white">Current NAV</span>
        <span className="text-green-500 ml-2">{liveFData.data[1].predicted_nav.toFixed(3)}</span>
        <span className="ml-3 text-white">Daily Performance </span>
        <span className={`${liveFData.data[1].dtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[1].dtd_performance_usd.toFixed(2))} (
          {(liveFData.data[1].dtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white">Monthly Performance </span>
        <span className={`${liveFData.data[1].mtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[1].mtd_performance_usd.toFixed(2))} (
          {(liveFData.data[1].mtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white ">Yearly Performance </span>
        <span className={`${liveFData.data[1].ytd_performance > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {(liveFData.data[1].ytd_performance * 100).toFixed(2)}%
        </span>
        <span className="text-white ml-24">{liveFData.data[0].officialName}:</span>
        <span className="ml-3 text-white">Current NAV</span>
        <span className="text-green-500 ml-2">{liveFData.data[0].predicted_nav.toFixed(3)}</span>
        <span className="ml-3 text-white">Daily Performance </span>
        <span className={`${liveFData.data[0].dtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[0].dtd_performance_usd.toFixed(2))} (
          {(liveFData.data[0].dtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white">Monthly Performance </span>
        <span className={`${liveFData.data[0].mtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}>
          ${numberWithCommas(liveFData.data[0].mtd_performance_usd.toFixed(2))} (
          {(liveFData.data[0].mtd_performance * 100).toFixed(2)}%)
        </span>
        <span className="ml-3 text-white ">Yearly Performance </span>
        <span className={`${liveFData.data[0].ytd_performance > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {(liveFData.data[0].ytd_performance * 100).toFixed(2)}%
        </span>
      </div>
    </div>
  );
};

export default Ticker;
