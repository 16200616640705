import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const DailyMetrics = ({ numberWithCommas, circleCommonClasses, selectedManagedFutures, selectedIcarusTrend }) => {
  let liveFData = useSelector((state) => state.liveFundData);
  const [currentDate, setCurrentDate] = useState('');

  //function to get, format and set currentDate

  useEffect(() => {
    const date = new Date();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const getAndFormatDate = () => {
      const month = months[date.getMonth()];
      return `${date.getUTCDate()} ${month} ${date.getFullYear()}`;
    };
    setCurrentDate(getAndFormatDate());
  }, []);

  return (
    <React.Fragment>
      <p className="ml-4 mt-2 opacity-80">Key Metrics on {currentDate}</p>
      <div className="flex flex-row justify-between mt-4 border-b border-green-800">
        <p className="ml-4 text-lg">NAV Price</p>
        {!liveFData.loading && !liveFData.error && selectedManagedFutures ? (
          <p className={`text-center text-white text-sm mr-4 text-lg font-semibold`}>
            ${numberWithCommas(liveFData.data[1].predicted_nav.toFixed(2))}
          </p>
        ) : !liveFData.loading && !liveFData.error && selectedIcarusTrend ? (
          <p className={`text-center text-white text-sm mr-4 text-lg font-semibold`}>
            ${numberWithCommas(liveFData.data[0].predicted_nav.toFixed(2))}
          </p>
        ) : (
          <div className="flex justify-center mt-2 mr-4">
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between border-b border-green-800 mt-4">
        <p className="ml-4 text-lg">24hr PnL</p>
        {!liveFData.loading && !liveFData.error && selectedManagedFutures ? (
          <p
            className={`font-semibold ${liveFData.data[1].dtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}
          >
            ${numberWithCommas(liveFData.data[1].dtd_performance_usd.toFixed(2))}{' '}
            <span className="text-sm font-sm">({(liveFData.data[1].dtd_performance * 100).toFixed(2)}%)</span>
          </p>
        ) : !liveFData.loading && !liveFData.error && selectedIcarusTrend ? (
          <p
            className={`font-semibold ${liveFData.data[0].dtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}
          >
            ${numberWithCommas(liveFData.data[0].dtd_performance_usd.toFixed(2))}{' '}
            <span className="text-sm font-sm">({(liveFData.data[0].dtd_performance * 100).toFixed(2)}%)</span>
          </p>
        ) : (
          <div className="flex justify-center mt-2 mr-4">
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between border-b border-green-800 mt-4">
        <p className="ml-4 text-lg">Monthly PnL</p>
        {!liveFData.loading && !liveFData.error && selectedManagedFutures ? (
          <p
            className={`font-semibold ${liveFData.data[1].mtd_performance_usd > 0 ? 'text-green-500' : 'text-red-500'}`}
          >
            ${numberWithCommas(liveFData.data[1].mtd_performance_usd.toFixed(2))}{' '}
            <span className="text-sm font-sm">({(liveFData.data[1].mtd_performance * 100).toFixed(2)}%)</span>
          </p>
        ) : !liveFData.loading && !liveFData.error && selectedIcarusTrend ? (
          <p className={`font-semibold ${liveFData.data[0].mtd_performance > 0 ? 'text-green-500' : 'text-red-500'}`}>
            ${numberWithCommas(liveFData.data[0].mtd_performance_usd.toFixed(2))}{' '}
            <span className="text-sm font-sm">({(liveFData.data[0].mtd_performance * 100).toFixed(2)}%)</span>
          </p>
        ) : (
          <div className="flex justify-center mt-2 mr-4">
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between mt-4 border-b border-green-800">
        <p className="ml-4 text-lg">AUM</p>
        {!liveFData.loading && !liveFData.error && selectedManagedFutures ? (
          <p className={`text-center text-white text-sm mr-4 text-lg font-semibold`}>
            ${numberWithCommas(liveFData.data[1].predicted_aum.toFixed(2))}
          </p>
        ) : !liveFData.loading && !liveFData.error && selectedIcarusTrend ? (
          <p className={`text-center text-white text-sm mr-4 text-lg font-semibold`}>
            ${numberWithCommas(liveFData.data[0].predicted_aum.toFixed(2))}
          </p>
        ) : (
          <div className="flex justify-center mt-2 mr-4">
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DailyMetrics;
