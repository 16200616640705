import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const BurgerNav = (props) => {
  const [toggle, setToggle] = useState(false);

  let isUserloggedIn = useSelector((state) => state.auth);

  const toggleBtn = () => {
    setToggle(!toggle);
  };

  return (
    <React.Fragment>
      <div id="burgerNavContainer" className="flex flex-row bg-gray-800 justify-between pb-2">
        <img
          className="h-20, w-24 ml-4 mt-4"
          src="https://res.cloudinary.com/fabien14/image/upload/v1625060703/Ryo-Bridge/Ryo_Bridge_logo_copy_rpd6vu.png"
          alt="Ryo Bridge Logo"
        ></img>
        <button id="hamburgerBtn" onClick={toggleBtn}>
          <img
            className={`${!toggle ? '' : 'hidden'} mr-4`}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAAAgUlEQVRYhe3VsQ2CYAAF4YPQEKhtaFiBWXQBQ+001m4As7CCjY01hNaCmNCRAMlv5L4JXl5zIEnHFgFc2sdtSPsq9Ji5bMy75lzfE4Ah7avX6XkNPWqueJcAxIF3LEpguvO7+FdkY96F3iBpD5ZkLUuylSWR/oUlWcuSbGVJJGnyAYdIN0uE0QxdAAAAAElFTkSuQmCC"
            width="30"
            height="30"
            alt="btn open logo"
          />
          <img
            className={`${toggle ? '' : 'hidden'} mr-4`}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRYhe3YsUrDQAAG4P+S5tIhblbbplMfwsnZueCsa2tdfQxHCa2juAqCm7OTDyGCja3WSQLaC+k5xJQaqLlLLkZK/ukId+TjyP0kAcpkC4kGJ84dLxISz2l/lwBAZfni7c5VMZpY9u73F2OtQIdQSmDWlMCsKYFZIwUkzzOYZyPgPZC/kxeAOi60p5nUMimgcT0FefyEee6CeOJI4gUwBy60hw9Ubqb5AdlhA/MGBZkwUMcV20kvgDF0QSYMfIvCP6jnB4Slg3XtEPnKYA4SkF4AOnShjUMcO2qCb1RWz88MlEEqwKUDiiAV4dIDf0MqxAGx1620yAhkOiNwHdBefCU4QEVRRzu5bYC8+SFu01CCUwNcEc5J8iSBZAdGz9z3zkU7mVhBfwKMH4hjG+yoJd6TuQJXnVbZMs8FmFQlCpHyQNGeU4SUA8qWsAKkFJBejENcnYL1bbGes3T4XRu8HiLp5Tg/oN+pYd6ugvVscEsXXsctHaxnY96uwu/UpIBSVc+bJli/JXWDxVpLT7V2vb5JikgJzJoSmDX/HvijB5f/bJZZl3wBQxULfYKOd5YAAAAASUVORK5CYII="
            width="30"
            height="30"
            alt="btn close logo"
          />
        </button>
      </div>
      {!isUserloggedIn.data && props.onHome && (
        <div className={`${toggle ? '' : 'hidden'} bg-gray-700 text-right text-bold`}>
          <Link to="/login" className="block text-white px-3 py-3 border-b-2 border-green-600">
            Login
          </Link>
          <Link to="/signup" className="block text-white px-3 py-3">
            Sign Up
          </Link>
        </div>
      )}
      {isUserloggedIn.data && props.onHome && (
        <div className={`${toggle ? '' : 'hidden'} bg-gray-700 text-right text-bold`}>
          <Link to="/account" className="block text-white px-3 py-3 border-b-2 border-green-600">
            Account
          </Link>
          <a href="/api-exp/logout" rel="noopener noreferrer" className="block text-white px-3 py-3">
            Logout
          </a>
        </div>
      )}
      {isUserloggedIn.data.admin && (
        <div className={`${toggle ? '' : 'hidden'} bg-gray-700 text-right text-bold`}>
          <Link to="/admin" className="block text-white px-3 py-3 border-b-2 border-green-600">
            Admin
          </Link>
        </div>
      )}
      {isUserloggedIn.data && props.onAccount && (
        <div className={`${toggle ? '' : 'hidden'} bg-gray-700 text-right text-bold`}>
          <p
            className="block text-white px-3 py-3 border-b-2 border-green-600"
            onClick={() => {
              props.selectManagedFutures();
              toggleBtn();
            }}
          >
            Managed Futures Fund
          </p>
          <p
            className="block text-white px-3 py-3 border-b-2 border-green-600"
            onClick={() => {
              props.selectIcarusTrend();
              toggleBtn();
            }}
          >
            Icarus Trend Fund
          </p>
          <Link to="/" className="block text-white px-3 py-3 border-b-2 border-green-600">
            Home
          </Link>
          <a href="/api-exp/logout" rel="noopener noreferrer" className="block text-white px-3 py-3">
            Logout
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default BurgerNav;

<Link className=" hover:text-green-500" to="/login">
  Login
</Link>;
