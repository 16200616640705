import React from 'react';
import { Link } from 'react-router-dom';
import GoogleButton from 'react-google-button';

const SignUp = () => {
  return (
    <div className="min-h-screen flex flex-row">
      <div id="accessAccountContainer" className="flex flex-col items-center">
        <div id="accessAccountLogoContainer" className="bg-gray-800 pt-4 pb-8 flex flex-row justify-center">
          <Link to="/">
            <img
              className="w-24"
              src="https://res.cloudinary.com/fabien14/image/upload/v1625060703/Ryo-Bridge/Ryo_Bridge_logo_copy_rpd6vu.png"
              alt="Ryo Bridge Logo"
            ></img>
          </Link>
        </div>
        <p className="mt-4 font-medium text-2xl">Sign up for further information</p>
        <a className="" href="/api-exp/auth/google" rel="noopener noreferrer">
          <GoogleButton style={{ width: '314px' }} label="Sign up with Google" className="mt-4" />
        </a>
      </div>
      <div id="accessAccountBackgroundContainer" className=""></div>
    </div>
  );
};

export default SignUp;
