import {
  fetchUser,
  fetchFundData,
  fetchMfundPerformanceData,
  fetchIfundPerformanceData,
  fetchMfundMonthlyPnLData,
  fetchIfundMonthlyPnLData,
  fetchAdminData,
} from './actionCreators';

const actions = {
  fetchUser,
  fetchFundData,
  fetchMfundPerformanceData,
  fetchIfundPerformanceData,
  fetchMfundMonthlyPnLData,
  fetchIfundMonthlyPnLData,
  fetchAdminData,
};
export default actions;
