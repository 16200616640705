import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../actions';

const Admin = () => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAdminData());
  }, [dispatch]);

  const [adminDataLoading, setAdminDataLoading] = useState(true);

  useEffect(() => {
    setAdminDataLoading(false);
  }, [adminDataLoading]);

  let adminData = useSelector((state) => state.adminData);
  let liveFData = useSelector((state) => state.liveFundData);

  if (adminDataLoading) {
    return <p></p>;
  }

  //Fallback logic whilst async op executes
  if (liveFData.loading) {
    return <p></p>;
  }

  const currentMfundNav = liveFData.data[1].predicted_nav;
  const currentIfundNav = liveFData.data[0].predicted_nav;

  // ${numberWithCommas((sub.tranch_USD * currentNav - sub.tranch_USD * sub.nav_price).toFixed(2))}

  console.log(currentMfundNav);
  console.log(currentIfundNav);

  const SubList = adminData.data.map((user) => {
    return (
      <React.Fragment>
        <h1 className="font-semibold mt-3">User: {user.name}</h1>
        <p className="mt-2">Managed Futures Fund</p>
        <tr>
          <td className="opacity-70 w-52">USD Amount</td>
          <td className="opacity-70">Price</td>
          <td className="opacity-70">PnL</td>
        </tr>
        {user.mFundSubs.map((sub) => {
          return (
            <tr>
              <td className="">{sub.tranch_USD}</td>
              <td className="">{sub.nav_price}</td>
              <td className="">${(sub.tranch_USD * currentMfundNav - sub.tranch_USD * sub.nav_price).toFixed(2)}</td>
            </tr>
          );
        })}
        <p className="mt-2">Icarus Trend Fund</p>
        <tr>
          <td className="opacity-70 w-52">USD Amount</td>
          <td className="opacity-70">Price</td>
          <td className="opacity-70">PnL</td>
        </tr>
        {user.iFundSubs.map((sub) => {
          return (
            <tr>
              <td className="">{sub.tranch_USD}</td>
              <td className="">{sub.nav_price}</td>
              <td className="">${(sub.tranch_USD * currentIfundNav - sub.tranch_USD * sub.nav_price).toFixed(2)}</td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <Link className="underline ml-5" to="/">
        Back To Home
      </Link>
      <table className="mt-2 ml-5 w-1/3 table-fixed">
        <tbody>{SubList}</tbody>
      </table>
    </React.Fragment>
  );
};

export default Admin;
