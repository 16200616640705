import React from 'react';

const BackgroundVideo = ({ videoSource, blur, children }) => {
  return (
    <div className="videoContainer">
      <video
        id="video"
        className=".object-cover video"
        src={videoSource}
        type="video/mp4"
        style={{ filter: `blur(${blur}px)`, WebkitFilter: `blur(${blur}px)` }}
        autoPlay="autoplay"
        loop="loop"
        muted
      ></video>
      {children}
    </div>
  );
};

export default BackgroundVideo;
