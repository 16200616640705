import { combineReducers } from 'redux';
import {
  authReducer,
  liveFundDataReducer,
  mfundPerformanceDataReducer,
  ifundPerformanceDataReducer,
  mfundMonthlyPnLDataReducer,
  ifundMonthlyPnLDataReducer,
  adminDataReducer,
} from './reducers';

export default combineReducers({
  auth: authReducer,
  liveFundData: liveFundDataReducer,
  mfundPerformanceData: mfundPerformanceDataReducer,
  ifundPerformanceData: ifundPerformanceDataReducer,
  mfundMonthlyPnLData: mfundMonthlyPnLDataReducer,
  ifundMonthlyPnLData: ifundMonthlyPnLDataReducer,
  adminData: adminDataReducer,
});
