import axios from 'axios';
import * as actionTypes from './types';

// Action creator is a function that returns an action object

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_USER_REQUEST });
    try {
      const res = await axios.get('/api-exp/current_user');
      dispatch({ type: actionTypes.FETCH_USER_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_USER_FAILURE, payload: error });
    }
  };
};

export const fetchFundData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FUND_DATA_REQUEST });
    try {
      const res = await axios.get('/api/live_perf');
      dispatch({ type: actionTypes.FETCH_FUND_DATA_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_FUND_DATA_FAILURE, payload: error });
    }
  };
};

export const fetchMfundPerformanceData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_MFUND_PERFORMANCEDATA_REQUEST });
    try {
      const res = await axios.get('/api/nav_data?fund_name=MASTER_FUND');
      dispatch({ type: actionTypes.FETCH_MFUND_PERFORMANCEDATA_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_MFUND_PERFORMANCEDATA_FAILURE, payload: error });
    }
  };
};

export const fetchIfundPerformanceData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_IFUND_PERFORMANCEDATA_REQUEST });
    try {
      const res = await axios.get('/api/nav_data?fund_name=I_FUND');
      dispatch({ type: actionTypes.FETCH_IFUND_PERFORMANCEDATA_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_IFUND_PERFORMANCEDATA_FAILURE, payload: error });
    }
  };
};

export const fetchMfundMonthlyPnLData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_MFUND_MONTHLY_PNL_REQUEST });
    try {
      const res = await axios.get('api/monthly_data?fund_name=MASTER_FUND');
      dispatch({ type: actionTypes.FETCH_MFUND_MONTHLY_PNL_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_MFUND_MONTHLY_PNL_FAILURE, payload: error });
    }
  };
};

export const fetchIfundMonthlyPnLData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_IFUND_MONTHLY_PNL_REQUEST });
    try {
      const res = await axios.get('api/monthly_data?fund_name=I_FUND');
      dispatch({ type: actionTypes.FETCH_IFUND_MONTHLY_PNL_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_IFUND_MONTHLY_PNL_FAILURE, payload: error });
    }
  };
};

export const fetchAdminData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ADMIN_DATA_REQUEST });
    try {
      const res = await axios.get('/api-exp/admin');
      dispatch({ type: actionTypes.FETCH_ADMIN_DATA_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ADMIN_DATA_FAILURE, payload: error });
    }
  };
};
