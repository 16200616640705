import * as actionTypes from '../actions/types';

let initialState = { data: '', loading: false, error: '' };

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_REQUEST:
      return { data: [], loading: true, error: '' };

    case actionTypes.FETCH_USER_SUCCESS:
      return { data: action.payload || false, loading: false, error: '' };

    case actionTypes.FETCH_USER_FAILURE:
      return { data: state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const liveFundDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FUND_DATA_REQUEST:
      return { data: [], loading: true, error: '' };
    case actionTypes.FETCH_FUND_DATA_SUCCESS:
      return { data: action.payload, loading: false, error: '' };
    case actionTypes.FETCH_FUND_DATA_FAILURE:
      return { data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mfundPerformanceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MFUND_PERFORMANCEDATA_REQUEST:
      return { data: [], loading: true, error: '' };
    case actionTypes.FETCH_MFUND_PERFORMANCEDATA_SUCCESS:
      return { data: action.payload, loading: false, error: '' };
    case actionTypes.FETCH_MFUND_PERFORMANCEDATA_FAILURE:
      return { data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ifundPerformanceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_IFUND_PERFORMANCEDATA_REQUEST:
      return { data: [], loading: true, error: '' };
    case actionTypes.FETCH_IFUND_PERFORMANCEDATA_SUCCESS:
      return { data: action.payload, loading: false, error: '' };
    case actionTypes.FETCH_IFUND_PERFORMANCEDATA_FAILURE:
      return { data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mfundMonthlyPnLDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MFUND_MONTHLY_PNL_REQUEST:
      return { data: [], loading: true, error: '' };
    case actionTypes.FETCH_MFUND_MONTHLY_PNL_SUCCESS:
      return { data: action.payload, loading: false, error: '' };
    case actionTypes.FETCH_MFUND_MONTHLY_PNL_FAILURE:
      return { data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ifundMonthlyPnLDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_IFUND_MONTHLY_PNL_REQUEST:
      return { data: [], loading: true, error: '' };
    case actionTypes.FETCH_IFUND_MONTHLY_PNL_SUCCESS:
      return { data: action.payload, loading: false, error: '' };
    case actionTypes.FETCH_IFUND_MONTHLY_PNL_FAILURE:
      return { data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADMIN_DATA_REQUEST:
      return { data: [], loading: true, error: '' };
    case actionTypes.FETCH_ADMIN_DATA_SUCCESS:
      return { data: action.payload, loading: false, error: '' };
    case actionTypes.FETCH_ADMIN_DATA_FAILURE:
      return { data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};
