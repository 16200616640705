import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ monthlyPnL }) => {
  const monthlyPnLData = Object.values(monthlyPnL.data.m_rets);
  const monthlyPnLTimeData = Object.values(monthlyPnL.data.dateTime);

  const monthlyPnLDataLength = Object.keys(monthlyPnLData).length;
  const monthlyPnLTimeLength = Object.keys(monthlyPnLTimeData).length;

  // Create new Arrays to only get data from past six months
  let sixMonthPnLData = [];
  let sixMonthlyPnLDate = [];

  // Loops to push six month data into new Arrays and format to % and stringDates respectively.

  for (let x = monthlyPnLDataLength - 1; x > monthlyPnLDataLength - 7; x--) {
    sixMonthPnLData.unshift((monthlyPnLData[x] * 100).toFixed(2));
  }

  for (let x = monthlyPnLTimeLength - 1; x > monthlyPnLTimeLength - 7; x--) {
    sixMonthlyPnLDate.unshift(
      new Date(monthlyPnLTimeData[x]).toLocaleString('en-US', { month: 'short', year: 'numeric' })
    );
  }

  const data = {
    labels: sixMonthlyPnLDate,
    datasets: [
      {
        label: '% Monthly PnL For Past Six Months',
        data: sixMonthPnLData,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    // tooltips: {
    //   enabled: false,
    // },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        //   position: 'bottom',
        labels: {
          boxWidth: 14,
          boxHeight: 6,
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        // grid: {
        //   display: false,
        // },
        ticks: {
          color: 'white',
          font: {
            size: 10,
          },
        },
      },
      x: {
        // grid: {
        //   display: false,
        // },
        ticks: {
          font: {
            size: 12,
          },
          maxTicksLimit: 6,
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Bar data={data} options={options} />
    </React.Fragment>
  );
};

export default BarChart;
