import React from 'react';
import { Line } from 'react-chartjs-2';

const VolChart = ({ volPerformance }) => {
  const volData = Object.values(volPerformance.data.realised_vol);
  const volTimeData = Object.values(volPerformance.data.dateTime);

  const volDataLength = Object.keys(volData).length;
  const volTimeLength = Object.keys(volTimeData).length;

  // Create new Arrays to ignore the null datasets
  let adjustedVolData = [];
  let adjustedVolTimeDate = [];

  // Loops to push and format relevant data into new Arrays

  for (let x = 90; x < volDataLength; x++) {
    adjustedVolData.push(volData[x]);
  }

  for (let x = 90; x < volTimeLength; x++) {
    adjustedVolTimeDate.push(new Date(volTimeData[x]).toLocaleString('en-US', { month: 'short', year: 'numeric' }));
  }

  // Chartjs Config

  const data = {
    labels: adjustedVolTimeDate,
    datasets: [
      {
        label: 'Volatility Over Time',
        data: adjustedVolData,
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        pointRadius: 0,
        hitRadius: 0,
      },
    ],
  };

  const options = {
    tooltips: {
      enabled: false,
    },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        //   position: 'bottom',
        labels: {
          boxWidth: 14,
          boxHeight: 6,
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        // grid: {
        //   display: false,
        // },
        ticks: {
          color: 'white',
          font: {
            size: 10,
          },
        },
      },
      x: {
        // grid: {
        //   display: false,
        // },
        ticks: {
          font: {
            size: 12,
          },
          maxTicksLimit: 12,
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Line data={data} options={options} />
    </React.Fragment>
  );
};

export default VolChart;
