import React from 'react';

const Subscriptions = ({ subAmount, numberWithCommas, navPrice }) => {
  // Helper variables
  let currentNav = navPrice.predicted_nav;
  let totalPnL = 0;

  //render user subList
  const subList = subAmount.map((sub) => {
    return (
      <tr key={sub.tranch_USD+(Math.random() * 100)}>
        <td className="text-center text-white border-green-800 border">${numberWithCommas(sub.tranch_USD)}</td>
        <td className="text-center text-white border-green-800 border">{numberWithCommas(sub.nav_price)}</td>
        {!sub.nav_price && <td className="text-center text-white border-green-800 border">$0.00</td>}
        {sub.nav_price && (
          <td
            className={`text-center border-green-800 border ${
              sub.tranch_USD * currentNav - sub.tranch_USD * sub.nav_price > 0 ? 'text-green-500' : 'text-red-500'
            }`}
          >
            ${numberWithCommas((sub.tranch_USD * currentNav - sub.tranch_USD * sub.nav_price).toFixed(2))}
          </td>
        )}
      </tr>
    );
  });

  // calculate total fund PnL based on user subList

  const firstSub = subAmount[0].tranch_USD;

  if (!firstSub) {
    totalPnL = 0;
  } else {
    for (let sub of subAmount) {
      totalPnL += sub.tranch_USD * currentNav - sub.tranch_USD * sub.nav_price;
    }
  }

  // ADD EMPTY SUB VALUES TO USERS LOGIN IN APP.JS.

  return (
    <React.Fragment>
      <table className="table-fixed mt-2">
        <thead>
          <tr>
            <th className="tableRow w-52 opacity-70">Amount</th>
            <th className="w-1/3 opacity-70">Price</th>
            <th className="w-1/3 opacity-70">Current PnL</th>
          </tr>
        </thead>
        <tbody className="">{subList}</tbody>
      </table>
      <p className="text-white text-center mt-8">Total Profit</p>
      <p className={`text-center ${totalPnL >= 0 ? 'text-green-500' : 'text-red-500'}`}>
        ${numberWithCommas(totalPnL.toFixed(2))}
      </p>
    </React.Fragment>
  );
};

export default Subscriptions;
