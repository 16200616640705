import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import NavBar from './NavBar/Navbar';
import BackgroundVideo from './Video/BackgroundVideo';
import Ticker from './Ticker/Ticker';
import Strategy from './Strategy/Strategy';
import BurgerNav from './NavBar/BurgerNav';

import './Video/BackgroundVideo.css';
import './Ticker/Ticker.css';
import './NavBar/NavStyling.css';

const Home = () => {
  const [FundDataLoading, setFundDataLoading] = useState(true);
  const [onAccount] = useState(false);
  const [onHome] = useState(true);

  let liveFData = useSelector((state) => state.liveFundData);

  useEffect(() => {
    setFundDataLoading(false);
  }, [FundDataLoading]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const ScrollToFundData = () => {
    const fundEle = document.getElementById('fundTickerServerOffline');
    fundEle.scrollIntoView({ behavior: 'smooth' });
  };

  // styling
  const videoSource =
    'https://res.cloudinary.com/fabien14/video/upload/v1625487020/Ryo-Bridge/Seamless_Bridge_y1hwha.mp4';

  const circleCommonClasses = 'h-2.5 w-2.5 bg-white rounded-full';

  if (FundDataLoading) {
    return <p></p>;
  }

  return (
    <React.Fragment>
      <BackgroundVideo blur={0.5} videoSource={videoSource}>
        <div className="content">
          <NavBar onHome={onHome} onAccount={onAccount} />
          <div id="homeTitle">
            <h1 className="text-center text-3xl mt-14 lg:mt-28 cursor-pointer" onClick={ScrollToFundData}>
              <span className="border-b-4 text-5xl">R</span>YO <span className="text-green-300">BRIDGE</span> CAPITAL
            </h1>
            <p className="text-center cursor-pointer mt-2" onClick={ScrollToFundData}>
              {' '}
              Family Office specalising in digital assets
            </p>
            <p className="text-center text-xl mt-2 cursor-pointer" onClick={ScrollToFundData}>
              ↧
            </p>
          </div>
        </div>
      </BackgroundVideo>
      <BurgerNav onAccount={onAccount} onHome={onHome} />
      <div id="fundTickerServerOffline"></div>
      {!liveFData.loading && !liveFData.error ? (
        <div id="fundTickerServerOnline" className="bg-gray-800">
          <Ticker numberWithCommas={numberWithCommas} />
        </div>
      ) : (
        ''
      )}
      <main className="sm:pt-1 sm:pt-0 sm:flex sm:flex-row sm:flex-1 sm:h-2/5 bg-gray-800">
        <div className="ml-4 sm:ml-14 sm:w-4/12 sm:ml-16">
          <div className="grid grid-cols-1 divide-y divide-green-500 pt-6 sm:pt-0 md:mt-8 lg:mt-20">
            <p className="text-xl sm:text-3xl font-semibold text-white">Invest in the Future</p>
            <p className="text-xl sm:text-3xl font-semibold text-white">Invest with Confidence</p>
            <p className="text-xl sm:text-3xl font-semibold text-white">
              Invest through <span className="border-b-4 text-4xl">R</span>yo{' '}
              <span className="text-green-500">Bridge</span> Capital{' '}
            </p>
          </div>
          <p className="sm:text-xl mt-12 mb-14 text-white">
            Ryo Bridge Capital is an established family office with its own proprietary investment algorithm which
            focuses on generating <span className="text-green-500">stable and substantial</span> returns from digital
            assets.
          </p>
        </div>
        <div id="liveFundDataContainer" className="sm:w-8/12">
          <p className="text-xl text-white font-semibold text-center sm:mt-32">
            {' '}
            <span className="border-b-2 border-green-600">LIVE PERFORMANCE</span>{' '}
          </p>
          <div className="flex flex-row justify-around mt-6">
            <div className="mt-8">
              <p className="text-xl font-medium text-center text-white">Managed Futures Fund</p>
              <p className="text-center opacity-50 mt-1 text-white">AUM</p>
              {!liveFData.loading && !liveFData.error ? (
                <p className="text-center text-white text-lg ">
                  ${numberWithCommas(liveFData.data[1].predicted_aum.toFixed(2))}
                </p>
              ) : (
                <div className="flex justify-center mt-2">
                  <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                  <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                  <div className={`${circleCommonClasses} animate-bounce400`}></div>
                </div>
              )}
              <p className="text-center opacity-50 text-white">MTD</p>
              {!liveFData.loading && !liveFData.error ? (
                <p
                  className={`text-center text-white text-sm ${
                    liveFData.data[1].mtd_performance_usd.toFixed(2) > 0 ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  ${numberWithCommas(liveFData.data[1].mtd_performance_usd.toFixed(2))} (
                  {(liveFData.data[1].mtd_performance * 100).toFixed(2)}%)
                </p>
              ) : (
                <div className="flex justify-center mt-2">
                  <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                  <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                  <div className={`${circleCommonClasses} animate-bounce400`}></div>
                </div>
              )}
              <p className="text-center opacity-50 text-white">YTD</p>
              {!liveFData.loading && !liveFData.error ? (
                <p
                  className={`text-center text-white text-lg font-bold ${
                    liveFData.data[1].ytd_performance > 0 ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  {(liveFData.data[1].ytd_performance * 100).toFixed(2)}%
                </p>
              ) : (
                <div className="flex justify-center mt-2">
                  <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                  <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                  <div className={`${circleCommonClasses} animate-bounce400`}></div>
                </div>
              )}
            </div>
            <div className="mt-8 mb-14">
              <p className="text-xl font-medium text-center text-white">Icarus Trend Fund</p>
              <p className="opacity-50 mt-1 text-center text-white">AUM</p>
              {!liveFData.loading && !liveFData.error ? (
                <p className="text-center text-white text-lg ">
                  ${numberWithCommas(liveFData.data[0].predicted_aum.toFixed(2))}
                </p>
              ) : (
                <div className="flex justify-center mt-2">
                  <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                  <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                  <div className={`${circleCommonClasses} animate-bounce400`}></div>
                </div>
              )}
              <p className="opacity-50 text-center text-white">MTD</p>
              {!liveFData.loading && !liveFData.error ? (
                <p
                  className={`text-center text-white text-sm ${
                    liveFData.data[0].mtd_performance_usd.toFixed(2) > 0 ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  ${numberWithCommas(liveFData.data[0].mtd_performance_usd.toFixed(2))} (
                  {(liveFData.data[0].mtd_performance * 100).toFixed(2)}%)
                </p>
              ) : (
                <div className="flex justify-center mt-2">
                  <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                  <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                  <div className={`${circleCommonClasses} animate-bounce400`}></div>
                </div>
              )}
              <p className="opacity-50 text-center text-white">YTD</p>
              {!liveFData.loading && !liveFData.error ? (
                <p
                  className={`text-center text-white text-lg font-bold ${
                    liveFData.data[0].ytd_performance > 0 ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  {(liveFData.data[0].ytd_performance * 100).toFixed(2)}%
                </p>
              ) : (
                <div className="flex justify-center mt-2">
                  <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                  <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                  <div className={`${circleCommonClasses} animate-bounce400`}></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      {!liveFData.loading && !liveFData.error ? (
        <div className="bg-gray-700">
          <Strategy />
        </div>
      ) : (
        ''
      )}
      <div className="flex bg-gray-800 h-46">
        <div className="flex flex-row">
          <div className="w-24">
            <img
              className="ml-4 sm:ml-14 mt-6"
              src="https://res.cloudinary.com/fabien14/image/upload/v1625060703/Ryo-Bridge/Ryo_Bridge_logo_copy_rpd6vu.png"
              alt="Ryo Bridge logo"
            ></img>
          </div>
          <div className="ml-14 sm:ml-24">
            <p className="mt-5 opacity-70 text-green-500">For more information:</p>
            <p className="font-medium text-white">Matthew Lee</p>
            <p className="font-medium text-white">Chief Investment Officer</p>
            <p className="font-medium text-white">matthew@ryobridge.com</p>
            <p className="mt-4 text-white mb-4">
              <span className="border-b-2 text-xl">R</span>yo <span className="text-green-500">Bridge</span> Capital
            </p>
          </div>
        </div>
      </div>
      <div className="h-14 w-full bg-gray-700">
        <p className="opacity-30 pt-3 text-white text-center">
          Copyright © 2021 Ryo Bridge Capital. All rights reserved.{' '}
        </p>
      </div>
    </React.Fragment>
  );
};

export default Home;
