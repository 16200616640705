import React, { useState } from 'react';

import NavBar from './NavBar/Navbar';
import './Account/Account.css';

const NotFound = () => {
  const [onAccount] = useState(false);
  const [onHome] = useState(false);

  return (
    <React.Fragment>
      <div id="accountNavContainer">
        <NavBar onAccount={onAccount} onHome={onHome} />
      </div>
      <div className="h-screen bg-gray-800">
        <h1 className="text-white text-center pt-40">404 - Page Not Found!</h1>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
