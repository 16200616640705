import React from 'react';
import { Line } from 'react-chartjs-2';

const NavChart = ({ fundPerformance, FundTitle }) => {
  const navData = fundPerformance.data.a_nav;
  const navPeriod = Object.values(fundPerformance.data.dateTime);

  // format date and push to new Array.
  const performancePeriod = [];

  for (let time in navPeriod) {
    performancePeriod.push(new Date(navPeriod[time]).toLocaleString('en-US', { month: 'long', year: 'numeric' }));
  }

  const data = {
    labels: performancePeriod,
    datasets: [
      {
        label: 'NAV Performance Since Inception',
        data: Object.values(navData),
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        pointRadius: 0,
        hitRadius: 0,
      },
    ],
  };

  const options = {
    tooltips: {
      enabled: false,
    },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 14,
          boxHeight: 6,
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            size: 10,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          maxTicksLimit: 12,
        },
      },
    },
  };
  return (
    <div className="h-96">
      <p id="masterFundGraphTitle" className="text-white mt-12 ml-4 sm:ml-14 text-green-500 mb-3">
        {FundTitle}
      </p>
      <Line data={data} options={options} height={400} />
    </div>
  );
};

export default NavChart;
