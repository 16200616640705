import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from '../../actions';

import NavBar from '../NavBar/Navbar';
import DailyMetrics from './DailyMetrics';
import VolChart from './VolChart';
import BarChart from './BarChart';
import Subscriptions from './Subscriptions';
import BurgerNav from '../NavBar/BurgerNav';

import './Account.css';

const Account = () => {
  // Create dispatch function for Monthly PnL data and dispatch on first render.
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchMfundMonthlyPnLData());
    dispatch(actions.fetchIfundMonthlyPnLData());
  }, [dispatch]);

  // standard variables
  const [accountDataLoading, setAcountDataLoading] = useState(true);
  const [onAccount] = useState(true);
  const [onHome] = useState(false);
  const [selectedManagedFutures, setSelectedManagedFutures] = useState(true);
  const [selectedIcarusTrend, setSelectedIcarusTrend] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setAcountDataLoading(false);
  }, [accountDataLoading]);

  // Access redux state
  let isUserloggedIn = useSelector((state) => state.auth);
  let liveMFundPerformance = useSelector((state) => state.mfundPerformanceData);
  let liveIFundPerformance = useSelector((state) => state.ifundPerformanceData);
  let mFundMonthlyPnL = useSelector((state) => state.mfundMonthlyPnLData);
  let iFundMonthlyPnL = useSelector((state) => state.ifundMonthlyPnLData);
  let liveFData = useSelector((state) => state.liveFundData);

  //Fallback logic whilst async op executes
  if (
    liveMFundPerformance.loading ||
    isUserloggedIn.loading ||
    mFundMonthlyPnL.loading ||
    iFundMonthlyPnL.loading ||
    liveFData.loading ||
    accountDataLoading
  ) {
    return <p></p>;
  }

  // Client side validation with re to accessing route before login
  if (!isUserloggedIn.data) {
    return <p className="text-3xl pt-20 text-center">Please login to see account information</p>;
  }

  // Defining Fund Subscription Props
  const mFundsubAmount = isUserloggedIn.data.mFundSubs;
  const iFundsubAmount = isUserloggedIn.data.iFundSubs;
  const currentMfundNav = liveFData.data[1];
  const currentIfundNav = liveFData.data[0];

  // Helper functions
  const toggleBtn = () => {
    setToggle(!toggle);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // Mobile Specific Helper Functions
  const selectManagedFutures = () => {
    setSelectedManagedFutures(true);
    setSelectedIcarusTrend(false);
  };

  const selectIcarusTrend = () => {
    setSelectedManagedFutures(false);
    setSelectedIcarusTrend(true);
  };

  //Named helper variables
  const mFFund = 'Managed Futures Fund';
  const iTFund = 'Icarus Trend Fund';

  //Styling
  const circleCommonClasses = 'h-2.5 w-2.5 bg-white rounded-full';

  return (
    <React.Fragment>
      <div className="bg-gray-500">
        <div id="accountNavContainer">
          <NavBar onAccount={onAccount} onHome={onHome} />
        </div>
      </div>
      <BurgerNav
        onAccount={onAccount}
        onHome={onHome}
        selectManagedFutures={selectManagedFutures}
        selectIcarusTrend={selectIcarusTrend}
      />
      <div className="sm:flex sm:flex-row">
        <div className="bg-gray-800 sm:h-screen sm:w-8/12">
          <div className="sm:flex flex-row justify-between">
            {selectedManagedFutures && (
              <p className="text-white font-medium text-xl pt-4 sm:pt-0 sm:mt-24 ml-4 sm:ml-8 mb-4">{mFFund}</p>
            )}
            {selectedIcarusTrend && (
              <p className="text-white font-medium text-xl pt-4 sm:pt-0 sm:mt-24 ml-4 sm:ml-8 mb-4">{iTFund}</p>
            )}
            <button id="SelectFundBtn" className="mt-20" onClick={toggleBtn}>
              <div className="flex flex-row">
                {selectedManagedFutures && (
                  <p className={`${!toggle ? '' : 'hidden'} mr-4 text-white opacity-70`}>{mFFund}</p>
                )}
                {selectedIcarusTrend && (
                  <p className={`${!toggle ? '' : 'hidden'} mr-4 text-white opacity-70`}>{iTFund}</p>
                )}
                <img
                  className={`${!toggle ? '' : 'hidden'} -mt-1 mr-4`}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAs0lEQVRIie2SOw7CMAyGf/c+SSVg73EqFpr0IgkshdN074OhXIbNDLRCgroPIcSSb7X9f7ZkIBAI/BEGqcomc22qsgkYJNUjKVy31lOEUjc2k4bjOk8pQqmuppAk44JBAxAANyaJ6zxl4qLvEZGLzyscgKyX2W7nju/hTHy5bfweBF4nECTEdF8aPi94STyAQy8Z5k7d1pmp8GWCz0uwZPP1MEg31qvWnKfe8mvJ78IDAYkHZItRVg1XwRIAAAAASUVORK5CYII="
                  width="30"
                  height="30"
                  alt="btn open logo"
                />
              </div>
              <img
                className={`${toggle ? '' : 'hidden'} mr-4`}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRYhe3YsUrDQAAG4P+S5tIhblbbplMfwsnZueCsa2tdfQxHCa2juAqCm7OTDyGCja3WSQLaC+k5xJQaqLlLLkZK/ukId+TjyP0kAcpkC4kGJ84dLxISz2l/lwBAZfni7c5VMZpY9u73F2OtQIdQSmDWlMCsKYFZIwUkzzOYZyPgPZC/kxeAOi60p5nUMimgcT0FefyEee6CeOJI4gUwBy60hw9Ubqb5AdlhA/MGBZkwUMcV20kvgDF0QSYMfIvCP6jnB4Slg3XtEPnKYA4SkF4AOnShjUMcO2qCb1RWz88MlEEqwKUDiiAV4dIDf0MqxAGx1620yAhkOiNwHdBefCU4QEVRRzu5bYC8+SFu01CCUwNcEc5J8iSBZAdGz9z3zkU7mVhBfwKMH4hjG+yoJd6TuQJXnVbZMs8FmFQlCpHyQNGeU4SUA8qWsAKkFJBejENcnYL1bbGes3T4XRu8HiLp5Tg/oN+pYd6ugvVscEsXXsctHaxnY96uwu/UpIBSVc+bJli/JXWDxVpLT7V2vb5JikgJzJoSmDX/HvijB5f/bJZZl3wBQxULfYKOd5YAAAAASUVORK5CYII="
                width="30"
                height="30"
                alt="btn close logo"
              />
            </button>
            <div id="accountDropdown" className={`${toggle ? '' : 'hidden'} absolute bg-white text-bold`}>
              <p
                className="block px-3 py-3 border-b-2 border-green-600 hover:bg-green-600 cursor-pointer"
                onClick={() => {
                  toggleBtn();
                  setSelectedManagedFutures(true);
                  setSelectedIcarusTrend(false);
                }}
              >
                {mFFund}
              </p>
              <p
                className="block px-3 py-3 hover:bg-green-600 cursor-pointer"
                onClick={() => {
                  toggleBtn();
                  setSelectedManagedFutures(false);
                  setSelectedIcarusTrend(true);
                }}
              >
                {iTFund}
              </p>
            </div>
          </div>
          <div className="sm:flex flex-row">
            <div id="metricsChartContainer" className="text-white bg-gray-700 rounded-lg sm:ml-6">
              <DailyMetrics
                numberWithCommas={numberWithCommas}
                circleCommonClasses={circleCommonClasses}
                selectedManagedFutures={selectedManagedFutures}
                selectedIcarusTrend={selectedIcarusTrend}
              />
            </div>
            <div id="barChartContainer" className="text-white bg-gray-700 rounded-lg mt-8 sm:mt-0 sm:ml-2">
              {selectedManagedFutures && !mFundMonthlyPnL.error && <BarChart monthlyPnL={mFundMonthlyPnL} />}
              {selectedIcarusTrend && !iFundMonthlyPnL.error && <BarChart monthlyPnL={iFundMonthlyPnL} />}
              {mFundMonthlyPnL.error ||
                (iFundMonthlyPnL.error && (
                  <p className="ml-4 text-white">Sorry - Monthly PnL chart not available at this time</p>
                ))}
            </div>
          </div>
          <div id="volGraphContainer" className="sm:ml-6 mt-8 text-white bg-gray-700 rounded-lg">
            {selectedManagedFutures && !liveMFundPerformance.error && (
              <VolChart volPerformance={liveMFundPerformance} />
            )}
            {selectedIcarusTrend && !liveIFundPerformance.error && <VolChart volPerformance={liveIFundPerformance} />}
            {liveIFundPerformance.error && (
              <p className="ml-4 text-white">Sorry - volatility chart not available at this time</p>
            )}
          </div>
        </div>
        <div className="bg-gray-800 h-screen pt-8 sm:pt-0 sm:w-4/12">
          <div id="subContainer" className="bg-gray-700 rounded-lg sm:mt-20">
            <p id="" className="text-white font-medium ml-4 sm:ml-6 sm:opacity-70 pt-4">
              My Subscriptions
            </p>{' '}
            {selectedManagedFutures && !liveFData.error && (
              <Subscriptions
                subAmount={mFundsubAmount}
                numberWithCommas={numberWithCommas}
                navPrice={currentMfundNav}
              />
            )}
            {selectedIcarusTrend && !liveFData.error && (
              <Subscriptions
                subAmount={iFundsubAmount}
                numberWithCommas={numberWithCommas}
                navPrice={currentIfundNav}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Account;
