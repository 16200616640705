import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavBar = (props) => {
  let isUserloggedIn = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      <nav id="NavHeader" className="flex flex-row sm:justify-between mt-5 h-10">
        <div className="flex flex-row space-x-9">
          <img
            className="ml-10 text-2xl"
            src="https://res.cloudinary.com/fabien14/image/upload/v1625060703/Ryo-Bridge/Ryo_Bridge_logo_copy_rpd6vu.png"
            alt="Ryo Bridge Logo"
          ></img>
          <Link
            className={`${
              props.onHome ? 'opacity-100 border-green-500 border-b-2' : 'text-white hover:text-green-500'
            } `}
            to="/"
          >
            Home
          </Link>
          {isUserloggedIn.data && (
            <Link
              className={`${
                props.onAccount ? 'text-white border-green-500 border-b-2' : 'text-white hover:text-green-500'
              } `}
              to="/account"
            >
              Account
            </Link>
          )}
          {isUserloggedIn.data.admin && (
            <Link className="text-white" to="/admin">
              Admin
            </Link>
          )}
        </div>
        {!isUserloggedIn.data && (
          <div className="flex flex-row space-x-9 mr-10">
            <Link className=" text-white hover:text-green-500" to="/login">
              Login
            </Link>
            <Link className="font-bold text-green-500 hover:text-green-300" to="/signup">
              Sign Up
            </Link>
          </div>
        )}
        {isUserloggedIn.data && props.onAccount && (
          <div className="flex flex-row">
            <img
              src={isUserloggedIn.data.profilePicture}
              alt="google profile pic"
              className="-mt-2 mr-2 rounded-full"
            ></img>
            <p className="text-white font-medium mr-2">{isUserloggedIn.data.name}</p>
            <a className="mr-6 ml-4 text-white hover:text-green-500" href="/api-exp/logout" rel="noopener noreferrer">
              Logout
            </a>
          </div>
        )}
        {isUserloggedIn.data && props.onHome && (
          <div className="flex flex-row">
            <a className="mr-10 text-white hover:text-green-500" href="/api-exp/logout" rel="noopener noreferrer">
              Logout
            </a>
          </div>
        )}
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
